<template>
  <div class="zhgl">
    <div class="banner">
      <img src="static/lvya/zhgl/banner.png" alt="" style="width: 100%;">
    </div>
    <Cont1></Cont1>
    <Cont2></Cont2>
    <Cont3></Cont3>
    <Cont4></Cont4>
    <Cont5></Cont5>
  </div>
</template>
<script>
import { WOW } from 'wowjs'
import Cont1 from './coms/cont1.vue'
import Cont2 from './coms/cont2.vue'
import Cont3 from './coms/cont3.vue'
import Cont4 from './coms/cont4.vue'
import Cont5 from './coms/cont5.vue'
export default {
  components: { Cont1, Cont2, Cont3, Cont4, Cont5 },
  mounted() {
    this.$nextTick(() => {
      let wow = new WOW({
          boxClass: 'wow',
          animateClass: 'animate__animated',
          offset: '0',
          mobile: true,
          live: true
      })
      wow.init()
    })
  },
}
</script>
